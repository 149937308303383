import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { FBUserModel } from '../model/user';

@Injectable({
    providedIn:  'root'
})

export class AppService {
    public curUser: FBUserModel;   // Current User Information

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _router: Router,
    ) {
        // Set the default
        this.curUser = new FBUserModel('', {});
    }

    setCurUser(user: FBUserModel): void{
        this.curUser = user;
    }
    getCurUser(): FBUserModel{
        return this.curUser;
    }
    getUserUid(): string{
        return this.curUser.uid;
    }

    onChangedCurUser(curUser: FBUserModel): void{
        this.curUser = curUser;
    }
}