import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn:  'root'
})
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    token = new BehaviorSubject(null);

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private _httpClient: HttpClient,
        private _db: AngularFireDatabase,
        private toastr: ToastrService
    ) {
        this.angularFireMessaging.messaging.subscribe((_messaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        })
    }

    requestPermission(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.angularFireMessaging.requestToken.subscribe((token) => {
                resolve(token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
                reject
            });
        })
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            // this.toastr.success(payload?.data?.content, "new message received.");
            this.currentMessage.next(payload);
        })
    }

    sendMessage(message: string) {
        // this._db.list(`${COLLECTION_TOKENS}`).valueChanges()
        // .subscribe((docs: any) => {
        //     const serverToken = docs[0];
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type':  'application/json',
                    'Authorization': 'key=AAAAPwZNkFc:APA91bFJiruhbkpt-Ej4XDrf-525BXs7aA-NE8nOXkkwLr2uEds8UD_UfO-TJEGA__hMEu2rYcEIHewID3UzT71Cbdpfd-Vpq9WrFVg-ZaWEN8bQw5CeRE9uZeiWFH52aND-A_Dyp32y'
                })
            };

            const data = {
                "data": {
                    "title": "New Order", 
                    "content": message
                },
                "to" : "/topics/new_order"
            }

            const baseUrl = 'https://fcm.googleapis.com/fcm/send';
            this._httpClient.post(baseUrl, JSON.stringify(data), httpOptions)
            .subscribe((response: any) => {
            })
        // })
    }
}
