
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const Menu = {
    text: 'Menu',
    link: '/menu',
    icon: 'icon-menu'
};

const Feedback = {
    text: 'Feedback',
    link: '/feedback',
    icon: 'icon-menu'
};

const AboutUs = {
    text: 'About Us',
    link: '/about',
    icon: 'icon-menu'
};

const ContactUs = {
    text: 'Contact Us',
    link: '/contact',
    icon: 'icon-menu'
};

const ShoppingCart = {
    text: 'Order Online',
    link: '/shopping',
    icon: 'icon-menu'
};

const BookATable = {
    text: 'Book a table',
    link: '/booking',
    icon: 'icon-menu'
};

const MyOrders = {
    text: 'My Orders',
    link: '/myorders',
    icon: 'icon-menu'
};

const MyAccount = {
    text: 'My Account',
    link: '/account-setting',
    icon: 'icon-menu'
};

export const menu = [
    Home,
    // Menu,
    ShoppingCart,
    BookATable,
    // AboutUs,
    // Feedback,
    ContactUs,
    MyOrders,
    MyAccount
];
