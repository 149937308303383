export class SizeModel {
    name: string;
    price: number
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class AddonModel {
    name: string;
    price: number
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class FoodModel{
    id: string;
    name: string;
    image: string;
    description?: string;
    price?: number;
    ratingCount?: number;
    ratingValue?: number;
    size: SizeModel[];
    addon: AddonModel[];
    rating: number;

    constructor(data: any){
        data = data || {};
        this.id = data.id || '';
        this.name = data.name || '';
        this.image = data.image || '';
        this.description = data.description || '';
        this.price = data.price || 0;
        this.ratingCount = data.ratingCount || 0;
        this.ratingValue = data.ratingValue || 0;

        if (data.size) {
            this.size = data.size.map((value: any) => new SizeModel(value));
        } else {
            this.size = [];
        }

        if (data.addon) {
            this.addon = data.addon.map((value: any) => new AddonModel(value));
        } else {
            this.addon = [];
        }

        if (data.ratingCount == 0) {
            this.rating = 0;
        } else {
            this.rating = data.ratingValue / data.ratingCount;
        }
    }

    calcRating() {
        return Math.round(this.ratingValue / this.ratingCount);
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this, {
            size: this.size ? this.size.map(value => value.toJSON()) : undefined,
            addon: this.addon ? this.addon.map(value => value.toJSON()) : undefined,
        });
        return JSON.parse(JSON.stringify(dicObject));
    }
}
