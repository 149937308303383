import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';

import { FoodModel, AddonModel, SizeModel } from 'src/app/model/food';

@Component({
    selector: 'app-food-extras',
    templateUrl: './food-extras.component.html',
    styleUrls: ['./food-extras.component.scss']
})

export class FoodExtrasComponent implements OnInit {

    title;
    data: any;
    checkStatus: boolean[] = [];
    public onClose: Subject<any>;

    constructor(
        private modalRef: BsModalRef
    ) {
    }

    ngOnInit() {
        this.onClose = new Subject();

        this.data.forEach((item, idx) =>this.checkStatus[idx] = item.isSelected)
    }

    public onConfirm(): void {
        let retVal: AddonModel[] = [];
        this.checkStatus.forEach((item, idx) => {
            if(item) {
                let temp = {
                    name: this.data[idx].name,
                    price: this.data[idx].price
                }
                retVal.push(new AddonModel(temp));
            }
        })
        this.onClose.next(retVal);
        this.modalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.modalRef.hide();
    }
}
