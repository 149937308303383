// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  modeTitle : 'environment',
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAgYTUxxMtnL3cI3qxsZ1aqufbTv8PXKK8",
    authDomain: "saijai-thai-massage.firebaseapp.com",
    databaseURL: "https://saijai-thai-massage-default-rtdb.firebaseio.com",
    projectId: "saijai-thai-massage",
    storageBucket: "saijai-thai-massage.appspot.com",
    messagingSenderId: "993335193699",
    appId: "1:993335193699:web:e4362027d82240c8590856",
    measurementId: "G-PNGE48BT01"
  },
  googleConfig :  {
      apiKey: 'AIzaSyBnizjLFp9y3CqMz2jHNs9RhHsSRrF2nn8',
      libraries: ['places']
  },
  stripeConfig : {
    apiKey: 'pk_test_Bq4jQmhxSay1bujwTwV0T9LS'
  },
  payment: {
    paypal: {
      clientId: 'AToqMoQVr0V6TRmhH03sTEoGsAxw-DLLMg-dkD7g88by8kRGU7wKw1k066RYKmsSW26kEN4iJKy11Eaj'
    }
  }
};
