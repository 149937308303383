import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { FoodModel, AddonModel, SizeModel } from 'src/app/model/food';

@Component({
    selector: 'app-food-detail',
    templateUrl: './food-detail.component.html',
    styleUrls: ['./food-detail.component.scss']
})

export class FoodDetailComponent implements OnInit {

    public event: EventEmitter<any> = new EventEmitter();
    title;
    data: FoodModel;
    constructor(
        private modalRef: BsModalRef
    ) {
    }

    ngOnInit() {
    }

    addToCart ( data )  {
        this.triggerEvent(data);
        this.modalRef.hide();
    }

    triggerEvent(data) {
        this.event.emit({ data: data });
    }

    onClose() {
        this.modalRef.hide();
    }
}