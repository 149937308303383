import { Component, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';

import { UserAuthService } from 'src/app/service/user-auth.service'
import { AppService } from 'src/app/service/app.service';
import { takeUntil } from 'rxjs/operators';
import { Subject,  Observable} from 'rxjs';

import { LoginComponent } from '../../routes/auth/login/login.component';
import { FBUserModel } from 'src/app/model/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    isLogin = false;

    isNavSearchVisible: boolean;
    modalRef: BsModalRef;
    private _unsubscribeAll: Subject<any>;

    constructor(public menu: MenuService, 
                public userblockService: UserblockService, 
                public settings: SettingsService, 
                public injector: Injector,
                private _authService: UserAuthService,
                private _appService: AppService,
                private _router: Router,
                private modalService: BsModalService) {

        // show only a few items on demo
        this.isLogin = this._authService.isAuthenticated()
        if (this.isLogin) {
            this.menuItems = menu.getMenu().slice(0, 5); // for horizontal layout
        } else {
            this.menuItems = menu.getMenu().slice(0, 3); // for horizontal layout
        }

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.isNavSearchVisible = false;

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

        this._authService.onUserInfoChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((fbUser: FBUserModel) => {
            if (fbUser.uid) {
                this.isLogin = true;
                this.menuItems = this.menu.getMenu().slice(0, 5); // for horizontal layout
            } else {
                this.isLogin = false;
                this.menuItems = this.menu.getMenu().slice(0, 3); // for horizontal layout
            }
        });
    }
    signInM() {
        this.modalRef = this.modalService.show(LoginComponent,  {
            initialState: {
                title: 'Food Detail'
            }
        });
        // this._router.navigate(['/login']); 
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    signOut() {
        this._authService.logout().then(() => {
            this.isLogin = false;
            this._appService.curUser = null;
            this.menuItems = this.menu.getMenu().slice(0, 3);
            this._router.navigate(['/home']);
        })
    }

    signIn() {
        this.modalRef = this.modalService.show(LoginComponent,  {
            initialState: {
                title: 'Food Detail'
            }
        });
        // this._router.navigate(['/login']);
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
